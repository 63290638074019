import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/atoms';
import ElectionTypeList from './ElectionTypeList';
import { getElectionsAdmin } from 'api/election';
import { getReport, loadDataAdmin } from 'api/inscriptionList';
import toast from 'react-hot-toast';

const ButtonToRepresentatives = () => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(`personeros`)}>
      Revisar Lista de Personeros Inscritos
    </Button>
  );
};
const ElectoralProcess = () => {
  const { electoralProcessId } = useParams();
  const [elections, setElections] = useState([]);
  const [selectElectorType, setSelectElectorType] = useState(false);
  const [errors, setErrors] = useState([]);
  const downloadReport = async (electoralProcessId) => {
    await getReport(electoralProcessId);
  };
  const onFileChange = async (event, electorType) => {
    setErrors([]);
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('electoralProcessCode', electoralProcessId);
    formData.append('electorType', electorType);
    formData.append('faculty', '');
    console.log(file);
    try {
      const data = await loadDataAdmin(formData);
      console.log('data', data);
      toast.success(
        `Archivo subido con éxito. ${
          data.insertedCount > 0 ? `Se insertaron $${data.insertedCount} registros. ` : ''
        }${
          data.modifiedCount > 0 ? `Se modificaron ${data.modifiedCount} registros.` : ''
        }`,
        { duration: 6000 },
      );
    } catch (err) {
      console.error(err.errors);
      toast.error('Error subiendo archivo');
      setErrors(err.errors);
    }
  };
  useEffect(() => {
    const init = async () => {
      const electionsData = await getElectionsAdmin({
        electoralProcessCode: electoralProcessId,
      });
      setElections(electionsData);
    };
    init();
  }, [electoralProcessId]);
  return (
    <div>
      <div className="text-4xl">Detalle del proceso electoral</div>
      <div className="my-3">
        {/*Código: <span className="text-gray-600">{electoralProcessId}</span>*/}
      </div>
      <div className="flex mb-2 space-x-5">
        <ButtonToRepresentatives />
        <Button onClick={() => downloadReport(electoralProcessId)}>
          Descargar reporte
        </Button>
        {selectElectorType ? (
          <>
            <label className="w-full flex items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide border border-blue cursor-pointer hover:bg-red-900 hover:text-white text-red-900 ease-linear transition-all duration-150">
              <span className="w-full text-center">Estudiante</span>
              <input
                type="file"
                className="hidden"
                onChange={(event) => onFileChange(event, 'STUDENT')}
                accept=".xls, .csv, .xlsx"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </label>
            <label className="w-full flex items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide border border-blue cursor-pointer hover:bg-red-900 hover:text-white text-red-900 ease-linear transition-all duration-150">
              <span className="w-full text-center">Docente</span>
              <input
                type="file"
                className="hidden"
                onChange={(event) => onFileChange(event, 'TEACHER')}
                accept=".xls, .csv, .xlsx"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </label>
          </>
        ) : (
          <label className="w-full flex items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide border border-blue cursor-pointer hover:bg-red-900 hover:text-white text-red-900 ease-linear transition-all duration-150">
            <span
              className="w-full text-center"
              onClick={() => {
                setSelectElectorType(true);
              }}
            >
              Suba archivo
            </span>
          </label>
        )}
      </div>
      {errors.length > 0 ? (
        <div className='my-4'>
          El archivo presenta errores en las siguientes filas:{' '}
          <span className="text-red-400 font-bold">
            {errors.map((error) => error.row).join(', ')}
          </span>
        </div>
      ) : null}
      <div className="mb-2">
        <ElectionTypeList elections={elections} />
      </div>
      <div>
        <ButtonToRepresentatives />
      </div>
    </div>
  );
};

export default ElectoralProcess;
