import { CheckCircleIcon } from '@heroicons/react/solid';
import { Loader } from 'components/atoms';
import { classNames } from 'utils';

const getIconStatus = (status) => {
  if (status == null || status === 'NONE') return null;
  if (status === 'LOADING') return <Loader />;
  if (status === 'SUCCESS')
    return (
      <span className="absolute right-2 inset-y-0 flex items-center pl-3">
        <CheckCircleIcon
          className="h-5 w-5 text-red-300 group-hover:text-red-400"
          aria-hidden="true"
        />
      </span>
    );
  if (status === 'ERROR') return null;
};

const Button = ({
  onClick,
  children,
  type = 'button',
  disabled = false,
  className,
  status,
  buttonType = 'primary',
}) => {
  const isDisabled = status === 'LOADING' || status === 'SUCCESS' || disabled;
  const baseClass =
    'group relative w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2';
  const buttonTypeClass =
    buttonType === 'primary'
      ? 'text-white bg-red-800 hover:bg-red-900 focus:ring-red-700'
      : 'text-white bg-gray-500 hover:bg-gray-600 focus:ring-gray-400';
  const classes = classNames(
    baseClass,
    buttonTypeClass,
    status === 'ERROR' || status === 'SUCCESS' ? 'cursor-default' : '',
    className,
  );
  return (
    <button type={type} className={classes} onClick={onClick} disabled={isDisabled}>
      {children}
      {getIconStatus(status)}
    </button>
  );
};

export default Button;
